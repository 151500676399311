import {Breadcrumb, Layout, Menu, MenuProps, Spin} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import React, {useMemo, useState} from 'react';
import '../../components/layout/style.less';
import {IsNull, NotNull} from '../../config/util';
import AuthLink from '../auth-link/auth-link';
import Logo from '../logo';
import {FullContent} from "../content/full-content";
import AccountOpt from "./account-opt";
import {MenuElement} from "../../config/router-parse";
import Global from "../../config/global";

type MenuItem = Required<MenuProps>['items'][number];


const LazyLoadSrc = (path) => {
    const Comp = React.lazy(() => import(`@src/${path}`));
    return (
        <React.Suspense fallback={<>   <Spin/></>}>
            <Comp/>
        </React.Suspense>
    );
};

const {Header, Content, Sider} = Layout;


function getItem(element: MenuElement): MenuItem {
    return {
        key: element.code,
        icon: NotNull(element.icon) ? LazyLoadSrc(element.icon) : undefined,
        children: element.menuList && element.menuList.length > 0 ? element.menuList.map(p => getItem(p)) : undefined,
        label: <AuthLink to={element.path}>{element.name}</AuthLink>,
    };
}


const homePage = Global.HomePage

export function AdminLayout(props) {
    const {children, codeMap, pathMap, loginUser} = props;
    const [collapsed, setCollapsed] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const menuList: MenuElement[] = useMemo(() => {
        return loginUser.getMenuList();
    }, [loginUser]);


    const items: MenuItem[] = useMemo(() => {
        const menus = []
        menuList.forEach(p => menus.push(getItem(p)));
        return menus;
    }, [menuList]);
    const currentPage = pathMap[window.location.pathname];


    const {selectedKeys, breadcrumbItem} = useMemo(() => {
        const selectedKeys = window.location.pathname === homePage ? [] : [currentPage.code];
        const breadcrumbItem = [];
        let current = loginUser.codeMapping[currentPage.code]

        while (NotNull(current)) {
            breadcrumbItem.unshift(<Breadcrumb.Item key={current.code}>{current.name}</Breadcrumb.Item>)
            current = loginUser.codeMapping[current.parent];
        }

        return {selectedKeys, breadcrumbItem}
    }, [currentPage, loginUser.codeMapping]);

    const openKeys = useMemo(() => {
        const keys = [];
        let current = currentPage;
        while (true) {
            if (!current.isPage()) {
                keys.push(current.code);
            }
            current = codeMap[current.parent];
            if (IsNull(current)) {
                break;
            }
        }

        return keys;
    }, [currentPage, codeMap]);

    const trigger = useMemo(() => {
        if (collapsed) {
            return <MenuUnfoldOutlined className="trigger" style={{padding: "12px"}}
                                       onClick={() => setCollapsed(!collapsed)}/>
        } else {
            return <MenuFoldOutlined className="trigger" style={{padding: "12px"}}
                                     onClick={() => setCollapsed(!collapsed)}/>
        }
    }, [collapsed]);

    return <>
        <Layout style={{minHeight: '100vh'}}>

            <Sider className={'ad'} collapsed={collapsed}
                   onCollapse={setCollapsed}>
                <Logo collapsed={collapsed} linkUrl={homePage}/>
                <Menu className={'adminLayout'} theme='dark' mode='inline'
                      items={items}
                      onSelect={(selectInfo) => {
                          selectedKeys[0] = selectInfo.key;
                          setRefresh(!refresh)
                      }}
                      onOpenChange={(item) => {
                          openKeys[0] = item.length > 0 ? item[item.length - 1] : undefined;
                          setRefresh(!refresh)
                      }}
                      selectedKeys={selectedKeys}
                      openKeys={openKeys}
                />
            </Sider>

            <Layout className='site-layout'>
                <Header className='site-layout-background' style={{padding: 0, height: '6vh'}}>
                    {trigger}
                    <AccountOpt loginUser={loginUser}/>
                </Header>
                <Content color={'white'} style={{padding: '0 16px', height: '80vh'}}>
                    <Breadcrumb style={{margin: '3px 10px 3px 0px', height: '2vh'}}>
                        {breadcrumbItem}
                    </Breadcrumb>
                    <FullContent style={{padding: '24px', minHeight: '50vh', backgroundColor: 'white'}}>
                        {children}
                    </FullContent>
                </Content>
            </Layout>
        </Layout>
    </>;
}

export default AdminLayout;
