import {Route, Routes} from 'react-router-dom';
import React, {useEffect, useMemo} from 'react';
import Global from '../config/global';
import Error404 from '../components/error/error404';
import {MenuElement, RouterCodeMap, RouterPathMap} from '../config/router-parse';
import loginUser from '../store/loginUser';
import AdminLayout from '../components/layout/index';
import Error403 from '../components/error/error403';
import {NotNull} from "../config/util";
import AdminProgress from "../components/progress";

export default function RouterApp() {

    useEffect(() => {
        const websitePath = window.location.pathname;
        const currentPage: MenuElement = RouterPathMap[websitePath]
        if (NotNull(currentPage)) {
            if (currentPage.auth && (!loginUser.login || !loginUser.hashMenuPath(websitePath))) {
                if (loginUser.login) {
                    window.location.href = "/403?path=" + websitePath
                } else {
                    window.location.href = Global.LoginPage
                }
            }
        }

    })

    const router = useMemo(() => {
        const router = [];
        const keys = Object.keys(RouterPathMap);
        for (let i in keys) {
            let pageInfo = RouterPathMap[keys[i]];
            if (pageInfo.isPage()) {
                if (pageInfo.type === "Bar" || pageInfo.home) {
                    router.push(<Route exact key={i} path={pageInfo.path}
                                       element={LazyLoadLayoutPage(pageInfo.element)}/>);
                } else {
                    router.push(<Route exact key={i} path={pageInfo.path}
                                       element={LazyLoadSrc(pageInfo.element)}/>);
                }
            }
        }
        router.push(<Route key={Math.random()} path={'*'} element={<Error404/>}/>);
        router.push(<Route key={Math.random()} path={'/404'} element={<Error404/>}/>);
        router.push(<Route key={Math.random()} path={'/403'} element={<Error403/>}/>);
        return router;
    }, []);

    return <Global.RouterType>
        <Routes>
            {router}
        </Routes>
    </Global.RouterType>;
}

export const LazyLoad = (path) => {
    const Comp = React.lazy(() => import(`${path}`));
    return (
        <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp/>
        </React.Suspense>
    );
};
export const LazyLoadSrc = (path) => {
    const Comp = React.lazy(() => import(`@src/${path}`));
    return (
        <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp/>
        </React.Suspense>
    );
};

export const LazyLoadLayoutPage = (path) => {
    const Comp = React.lazy(() => import(`@src/${path}`));
    return (
        <AdminLayout children={<React.Suspense fallback={<><AdminProgress/></>}><Comp/> </React.Suspense>}
                     codeMap={RouterCodeMap} pathMap={RouterPathMap}
                     loginUser={loginUser}/>
    );
};


