import React from 'react';
import {ConfigProvider} from 'antd';
import RouterApp from './router/Router-App';
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn';

export default function App() {
    return (
        <>
            <ConfigProvider locale={zhCN}>
                <React.Suspense >
                    <RouterApp/>
                </React.Suspense>
            </ConfigProvider>

        </>

    );
}


