import React, {CSSProperties} from 'react';
import {Select} from 'antd';
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {InputStatus} from "antd/lib/_util/statusUtils";

class AdminStaticSelectProps {
    disabled: boolean;
    mode: 'multiple' | 'tags';
    label: string;
    placeholder: string;
    datasource: [];
    onSelect: (value)=> {};
    onChange: (value)=> {};
    column: { key: string, name: string };
    name: string;
    initialValue: any;
    allowClear: boolean;
    value: any;
    showSearch: boolean;
    style: CSSProperties;
    maxTagCount: number;
    form: FormInstance;
    status: InputStatus;

}


export default class StaticSelect extends React.Component<AdminStaticSelectProps, any> {

    state = {
        placeholder: undefined,
        onChange: undefined,
        selectItems: undefined,
        multi: undefined,
    }

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onDeselect = this.onDeselect.bind(this);
        this.state.multi = (props.mode === 'multiple' || props.mode === 'tags')
        if (this.state.multi) {
            this.state.placeholder = '请输入' + (props.label ? props.label : props.placeholder);
        } else {
            this.state.placeholder = '请选择' + (props.label ? props.label : props.placeholder);
        }
        this.state.datasource = props.datasource;
        this.state.selectItems = props.value;
        this.state.onChange = this.onChange;


    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.value && (prevProps.value !== this.props.value)) {
            this.setState({selectItems: this.props.value})
        }
    }

    onChange(selected) {
        const {onChange, mode} = this.props;
        if (mode === "multiple" || mode === "tags") {
            let {selectItems} = this.state;
            if (selectItems === undefined) {
                selectItems = [];
            }
            selectItems.push(selected)
            onChange?.(selectItems)
            this.setState({selectItems: selectItems})

        } else {
            onChange?.(selected)
            this.setState({selectItems: selected})
        }
    }

    onDeselect(unSelect) {
        const {onChange, mode} = this.props;
        if (mode === "multiple" || mode === "tags") {
            let {selectItems} = this.state;
            if (selectItems === undefined) {
                selectItems = [];
            }
            selectItems = selectItems.filter(p => p !== unSelect)
            onChange?.(selectItems)
            this.setState({selectItems: selectItems})

        } else {
            onChange?.(undefined)
            this.setState({selectItems: undefined})
        }

    }

    onClear() {
        const {onChange} = this.props
        onChange?.(undefined)
        this.setState({selectItems: undefined})
    }

    render() {
        const props = this.props;
        const {
            disabled,
            suffixIcon,
            column = {key: "key", name: "name"},
            value,
            allowClear,
            style,
            datasource,
            showSearch = true
        } = this.props;
        const {placeholder, onSelect, selectItems} = this.state;
        const layout = {
            disabled,
            suffixIcon,
            allowClear
        };
        const width = style?.width ? style?.width : "180px";
        return (
            <Select
                {...layout}
                status={props.status}
                mode={props.mode}
                placeholder={placeholder}
                maxTagCount={this.props.maxTagCount}
                onChange={onSelect}
                onDeselect={this.onDeselect}
                defaultValue={props.initialValue}
                onClear={this.onClear}
                onSelect={this.onChange}
                value={selectItems ? selectItems : value}
                showSearch={showSearch}
                style={{...style, width: width}}
            >
                {datasource?.map((item) => (
                    <Select.Option key={item[column.key]}
                                   value={item[column.key]}>
                        {item[column.name]}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}
