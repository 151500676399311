import {ROUTER_DATA} from './global';
import {Copy, NotNull} from './util';


export class MenuElement {
    parent: string = ""; // 父级编码
    code: string = ""; // 编码
    type: 'Button' | 'Bar' | 'Link'; // 类型
    name: string = ""; // 名称
    path: string = ""; // 地址
    auth: boolean = true; // 是否验证权限
    innerChain: boolean = true; // true 内部地址 false 外部地址
    menuList: MenuElement[] = [];
    home: boolean = false; // 是否为首页 (非接口参数)
    element: string = ""; // 绑定组件 (非接口参数)
    icon: string = ""; // icon (非接口参数)

    isPage = () => {
        return NotNull(this.path) && this.path !== '#';
    };

    hasElement = () => {
        return this.element !== null && this.element !== undefined;
    };
}

function toMenuElement(data: any, hasChildren: boolean = true): MenuElement {
    const {auth = true, innerChain = true} = data;
    const element = new MenuElement();
    element.parent = data.parent;
    element.code = data.code;
    element.home = data.home;
    element.type = data.type;
    element.name = data.name;
    element.path = data.path;
    element.auth = auth;
    element.innerChain = innerChain;
    element.icon = data.icon;
    element.element = data.element;
    if (hasChildren) {
        element.menuList = data.menuList;
    }
    return element;
}

export function CopyElement(element: MenuElement, filterCode: []): MenuElement {
    const adminMenu = Copy(element, new MenuElement());
    if (adminMenu.menuList) {
        adminMenu.menuList = element.menuList.filter(p => filterCode.indexOf(p.code) !== -1)
            .map(p => {
                const {menuList} = p;
                p.menuList = menuList?.filter(p2 => p2.type !== "Button")
                return p
            })
    }
    return adminMenu;
}

export function loadMenuElementMapping(set: Set, routerData: MenuElement[], codeMap: {}, pathMap: {}) {
    for (let i in routerData) {
        const element = toMenuElement(routerData[i]);
        if (NotNull(codeMap[element.code])) {
            throw new DOMException(element.code + ' ，当前菜单编码重复');
        }
        codeMap[element.code] = element;

        if (element.isPage()) {
            if (NotNull(codeMap[element.path])) {
                throw new DOMException(element.path + ' ，当前请求地址重复');
            }
            pathMap[element.path] = element;
        }
        if (NotNull(element.menuList) && element.menuList.length !== 0) {
            loadMenuElementMapping(set, element.menuList, codeMap, pathMap);
        }
    }
}

const RouterCodeMap = {};
const RouterPathMap = {};
loadMenuElementMapping(new Set(), ROUTER_DATA, RouterCodeMap, RouterPathMap);
export {RouterCodeMap, RouterPathMap};
