import miniLogo from './mini-logo.png';
import './style.less';
import React, {useMemo} from 'react';
import AuthLink from '../auth-link/auth-link';
import Global from '../../config/global';
import {Divider, Tooltip} from "antd";

export default function Logo(props) {
    const {collapsed, linkUrl} = props;
    const logo = useMemo(() => {
        return collapsed ? (
                <div className={'mini-logo'}>
                    <img className='mini-logoImg' src={miniLogo} alt='mini-logoImg'/>
                </div>
            ) :
            (

                <div className={'logo'}>
                    <img className='logoImg' src={miniLogo} alt='logo'/>
                </div>

            )
    }, [collapsed])

    return (
        <>
            <AuthLink to={linkUrl}>
                <Tooltip placement={'right'} title={'前往首页'}> {logo}</Tooltip>
            </AuthLink>

            <Divider style={{color: "white"}} plain orientation="center">{Global.AppName}</Divider>
        </>
    );
}
