import Global from "./global";
import React, {useMemo, useState} from "react";
import StaticSelect from "../components/select/static-select";
import {SettingOutlined} from "@ant-design/icons";


export default function EnvironmentalSwitchSelect(props) {
    const {onSelect} = props;
    const env = Global.NODE_ENV;
    const [baseUrl, setBaseUrl] = useState(Global.BaseUrl);

    const environment = useMemo(() => {
        if (env === "development") {
            return require("./environmental.json")
        } else {
            return []
        }
    }, [env])

    return useMemo(() => {
        if (env === "development") {
            return <StaticSelect onChange={value => {

                Global.BaseUrl = value
                localStorage.setItem(Global.LOCAL_URL_KEY, value)
                onSelect?.(value)
                setBaseUrl(value)
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            }}
                                 style={{border: "1px solid #0000000a"}}
                                 value={baseUrl}
                                 suffixIcon={<SettingOutlined/>}
                                 datasource={environment}
                                 column={{key: "addr", name: "name"}}
                                 placeholder={"环境"}/>
        } else {
            return <></>
        }

    }, [env, environment, baseUrl])

}
